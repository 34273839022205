import React from 'react';


const ChouseUs = () => {
    return ( <div className='container'>
        <div className='row'>
            <div className='col-12 py-4'>
            <h3 className='roboto-bold'> Why Choose <span className='text-primary'>US?</span></h3>
            </div>
            <div className='col-12 py-2'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='bg-white py-2 mb-3 rounded'>
                        <div className='py-1'>
                            <ion-icon name="globe-outline"></ion-icon>
                        </div>
                        <div className='py-2'>
                            <h5 className='roboto-bold'>Modern Design</h5>
                            {/* <p className='roboto-light'>We have an Expert-level understanding of web development technologies and tools, including HTML, CSS,Bootstrap, JavaScript and various front-end frameworks and libraries.</p> */}
                            <p className='roboto-light'>Our team leverages deep expertise in web development, including HTML, CSS, Bootstrap, JavaScript, and advanced frameworks, to craft exceptional user experiences.</p>
                            </div>
                            </div>
                    </div>

                    <div className='col-12 text-center'>
                    <div className='bg-white py-2 mb-3 rounded'>
                        <div className='py-1'>
                                <ion-icon name="code-slash-outline" ></ion-icon>
                        </div>
                        <div className='py-2'>
                            <h5 className='roboto-bold'>Creative Design</h5>
                            {/* <p className='roboto-light'>Strong experience in Angular and React.JS and its core concepts including React-bootstrap, agGrid,charts.js Dashboards design, Unit testing, routing, use to navigate pages, custom design and migrate Designs best practices.</p> */}
                            <p className='roboto-light'>Expert in Angular and React.js, with experience in core concepts like React-Bootstrap, agGrid, charts.js, and best practices for building user interfaces, unit testing, routing, and custom design migration.</p>
                            </div>
                            </div>
                    </div>

                    <div className='col-12 text-center'>
                    <div className='bg-white py-2 mb-3 rounded'>
                        <div className='py-1'>
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <div className='py-2'>
                            <h5 className='roboto-bold'>Modern Development</h5>
                            {/* <p className='roboto-light'>Great understanding of clean code and SOLID principles. Well-versed with agile methodology and technology toolsets such as CI/CD,Static code analysis tools.</p> */}
                            <p className='roboto-light'>I champion clean code and SOLID principles to deliver maintainable and scalable software. Agile methodologies and CI/CD pipelines are my tools for efficient development and rapid delivery.</p>
                            </div>
                            </div>
                    </div>
              </div>
            </div>

        </div>
        
    </div>)
}

export default ChouseUs;
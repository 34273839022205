import React from 'react';



const Contact = () => {
    return (  <div className='container'>
    <div className='row'>
        <div className='col-12 py-4'>
            <h3 className='roboto-bold'> Contact <span className='text-primary'>Us</span></h3>
            </div>
        
<div className='col-12 py-2'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-5'>
                        <h4 className='roboto-bold'> Locations </h4>
                        <div className='py-1 w-100'>
                        <h6 className='roboto-bold'>Mahabubnagar:</h6>
                    <p className='py-1 roboto-regular'>H.No:- 5-71,Gajulapeta,(Mandal & Dist) Mahabubnagar-509382.</p>
                        </div>

                        <div className='py-1 w-100'>
                        <h6 className='roboto-bold'>Warangal:</h6>
                    <p className='py-1 roboto-regular'>H.No:- 12-7-144, Ramannapet, warangal 506002.</p>
                        </div>
                          
                    </div>

                    <div className='col-12 col-sm-12 col-md-5 col-lg-5'>
                    <div className='py-1 w-100'>
                            <div className='py-1'>
                                <h5 className='roboto-bold'>Email:</h5>
                                <p className='m-0 roboto-regular'>mailto: admin@bestcodetechnology.in</p>
                                <p className='roboto-regular'>website: www.bestcodetechnology.in</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-2 col-lg-2'>
                    <div className='py-1 w-100'>
                            <div className='py-2'>
                                <h5 className='roboto-bold'>Call:</h5>
                                <p className='py-1 roboto-regular'>+91 9515530212</p>
                            </div>
                        </div>
                    </div>

                  
                </div>
            </div>



        </div>
        </div>)
}

export default Contact;
import React from 'react';
import webDesign from '../assets/images/services/web-design.png'
import webDev from '../assets/images/services/web-service.png'
import UIIMG from '../assets/images/services/ui.png'
import AppIMG from '../assets/images/services/web-programming.png'

const Services = () => {
    return ( <div className='container'>
        <div className='row'>
            <div className='col-12 py-4'>
                <h3 className='roboto-bold'> Our <span className='text-primary'>Services</span></h3>

                {/* <p className='py-2 roboto-regular'>We design and build websites and mobile applications. Our work begins with planning and ends with execution. We use the latest technologies to serve the purpose of the website/ mobile application and looks amazing but keeps functionality and responsiveness at the forefront..</p> */}
            </div>
            <div className='col-12 py-2'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3'>
                        <div className='w-100 shadow-lg p-3 mb-5 rounded '>
                        <div className='py-2 mb-2'>
                                <img src={webDesign} alt="web" style={{width:"100px",height:"80px"}} />
                                
                        </div>
                        <div className='py-2'>
                            <h5 className='roboto-bold'>Web Design</h5>
                            <p className='roboto-light' style={{lineHeight: "28px"}}>Transform your business with a dynamic website. We craft stunning, cost-effective web designs that bring your ideas to life and propel your growth.</p>
                        <p className='read_more rounded roboto-bold'>Read More</p>
                        </div>
                        </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3'>
                    <div className='w-100 shadow-lg p-3 mb-5 rounded '>
                        <div className='py-2 mb-2'>
                                <img src={webDev} alt="web" style={{width:"100px",height:"80px"}}/>
                        </div>
                        <div className='py-2'>
                            <h5 className='roboto-bold'>Web Development</h5>
                            <p className='roboto-light'>Leverage our expert React team's experience in cross-platform development to build cutting-edge web applications and dynamic websites that drive profitable business transformation.</p>
                        <p className='read_more rounded roboto-bold'>Read More</p>
                            </div>
                            </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3'>
                    <div className='w-100 shadow-lg p-3 mb-5 rounded '>
                        <div className='py-2 mb-2'>
                                <img src={UIIMG} alt="web" style={{width:"100px",height:"80px"}}/>
                        </div>
                        <div className='py-3'>
                            <h5 className='roboto-bold'>UI&UX</h5>
                            <p className='roboto-light lh-lg'>Merge design, cutting-edge tech, and our UI/UX experts' experience to craft impactful websites and mobile apps that propel your business forward.</p>
                        <p className='read_more rounded roboto-bold'>Read More</p>
                            </div>
                            </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3'>
                    <div className='w-100 shadow-lg p-3 mb-5 rounded '>
                        <div className='py-2 mb-2'>
                                <img src={AppIMG} alt="web" style={{width:"100px",height:"80px"}}/>
                        </div>
                        <div className='py-3'>
                            <h5 className='roboto-bold'>Application Development</h5>
                            <p className='roboto-light lh-lg'>Our React experts leverage cutting-edge technologies to craft powerful web applications that seamlessly work across platforms.</p>
                        <p className='read_more rounded roboto-bold'>Read More</p>
                            </div>
                           </div> 
                    </div>
                    
             </div>
            </div>
        </div>
    </div>)
}

export default Services;
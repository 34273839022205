import React from 'react';

// import '../assets/header.css';
import logo from '../assets/images/logo.png';

const Header = () => {
  // const navigate = useNavigate();

  // const changeURL = (url) => {
  //   navigate(url)
  // }


    return ( <div className="container navbar navbar-expand-lg custom_nav-container px-2">
        <a className="navbar-brand" href="#home">
        <img src={logo} alt="logo"
                style={{ width: "100%", height: "50px" }}
            />
            {/* <span>
               
                CV Sagar Tech Pvt Ltd
            </span> */}
        </a>
        

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-controls="collapseWidthExample" aria-label="Toggle navigation" >
        <span className=""> </span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ">
              <li className="nav-item" >
            <a className="nav-link roboto-regular" href="#home">Home</a>
              </li>
              <li className="nav-item">
              <a className="nav-link roboto-regular" href="#service" > Services</a>
                </li>
                <li className="nav-item">
                <a className="nav-link roboto-regular" href="#whyUs">Why Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link roboto-regular" href="#technology" >Technologies</a>
              </li>
              <li className="nav-item">
                <a className="nav-link roboto-regular" href="#contact">Contact Us</a>
              </li>
              {/* <form class="form-inline">
                <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </form> */}
            </ul>
          </div>
        
    </div>)
}

export default Header;
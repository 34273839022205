import React from "react";
import Header from "../components/Header";
import Slide from "../components/Slide";
import Services from "../components/Services";
import ChouseUs from "../components/ChouseUs";
import Technologies from "../components/Technologies";
import Contact from "../components/Contact";

const Layout = () => {
  return (
    <div>
      <nav id="navbar-example2" className="bg-primary py-2 text-white">
        <Header />
      </nav>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        className="scrollspy-example bg-body-tertiary"
        tabindex="0"
      >
        <div id="home">
          <Slide />
        </div>
        <div id="service">
          <Services />
        </div>
        <div id="whyUs" className="bg-body-secondary">
          <ChouseUs />
        </div>
        <div id="technology">
          <Technologies />
        </div>
        <div id="contact" className="bg-body-secondary">
          <Contact />
        </div>

        <section className=" bg-dark py-3">
          <div className="container text-center  border-top border-white text-white py-2">
            <p>
              &copy; <span id="displayYear">2024</span> All Rights Reserved By
              <a href="https://bestcodetechnology.in/" className="px-2">
                Best Code Technology
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Layout;

import React from 'react';
import reactImg from '../assets/images/technologies/react.png'
import htmlImg from '../assets/images/technologies/html.svg'
import cssImg from '../assets/images/technologies/css.png'
import bootstrapImg from '../assets/images/technologies/bootstrap.png'
import javascriptImg from '../assets/images/technologies/javascript.png'
import nodeImg from '../assets/images/technologies/node.jpg'
import mongoDBImg from '../assets/images/technologies/mongodb.svg'
import angularImg from '../assets/images/technologies/Angular.png'





const Technologies = () => {
    return ( <div className='container'>
        <div className='row'>
            <div className='col-12 py-4'>
            <h3 className='roboto-bold'> Technologies <span className='text-primary'> We Use</span></h3>
            </div>
            <div className='col-12 py-2'>
                <div className='row'>
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={reactImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={htmlImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={cssImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={bootstrapImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={javascriptImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={nodeImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={mongoDBImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    <div className='col-12 col-sx-12 col-sm-6 col-md-4 col-lg-2'>
                        <div className='py-2 mb-4 border rounded text-center'>
                            <img src={angularImg} alt="react" style={{ width: "150px", height: "150px" }} />
                            </div>
                    </div> 
                    
              </div>
            </div>
        </div>
    </div>)
}

export default Technologies;